(function ($, Drupal, generic, site) {
  'use strict';

  site.template = site.template || {};

  var siteMyMacV1 = {
    formatter: $('.site-my-mac-v1'),
    signed_in: typeof site.userInfoCookie == 'undefined' ? '0' : site.userInfoCookie.getValue('signed_in'),
    is_mobile: typeof site.client.isMobile == 'undefined' ? '0' : site.client.isMobile
  };

  Drupal.behaviors.siteMyMacV1 = {
    get: function (key) {
      return siteMyMacV1[key];
    },

    set: function (key, val) {
      siteMyMacV1[key] = val;
    },

    setMyMac: function (self, thisFormatter) {
      // set first name from the cookie
      if (self.get('signed_in') == '1') {
        var firstName = site.userInfoCookie.getValue('first_name');

        if (firstName && firstName.length > 7) {
          firstName = '';
        }
        thisFormatter.find('.first-name').html(firstName);
      }
    },

    showMyMacContents: function (thisFormatter) {
      var self = this;
      var $triggerInput = thisFormatter.find('.js-gnav-util-trigger');
      var $triggerReset = thisFormatter.find('.js-gnav-util-trigger-reset');

      $(document).trigger('click');

      if (self.get('is_mobile') == '0') {
        if (self.get('signed_in') === '0' && Drupal.settings.enable_shared_login_redirect) {
          window.location = '/account/signin.tmpl';
        } else {
          thisFormatter.addClass('active');
        }

        if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr20) {
          $triggerInput.prop('checked', true);
        }

        var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
        var siteContainer = $('.site-header').siblings('.site-container');

        dimOverlay.prependTo(siteContainer);

        thisFormatter.bind('clickoutside', function () {
          thisFormatter.removeClass('active').unbind('clickoutside');
          dimOverlay.remove();
          $triggerReset.trigger('click');
        });

        thisFormatter.find('.site-my-mac__contents-close').once().click(function (e) {
          e.preventDefault();
          thisFormatter.find('.js-my_mac_link').attr('aria-expanded', 'false').focus();
          thisFormatter.trigger('clickoutside');
        });
      }
    },

    setContentsTitle: function (thisFormatter, showAltTitle) {
      var myMacContents = thisFormatter.find('.site-my-mac__contents');

      if (showAltTitle === true) {
        myMacContents.find('.site-my-mac__contents-title').hide();
        myMacContents.find('.site-my-mac__contents-title__alt').show();
      } else {
        myMacContents.find('.site-my-mac__contents-title').show();
        myMacContents.find('.site-my-mac__contents-title__alt').hide();
      }
    },

    setContentsOffset: function (thisFormatter) {
      var siteBag = $('.site-header .site-bag');

      if (siteBag.length === 1) {
        var siteBagWidth = siteBag.outerWidth(false);

        thisFormatter.find('.site-my-mac__contents').css({
          right: '-' + siteBagWidth + 'px'
        });
      }
    },

    setMyMacContents: function (thisFormatter, signed_in) {
      var myMacContents = thisFormatter.find('.site-my-mac__contents');

      if (signed_in === true) {
        myMacContents.find('.site-my-mac__contents-signin').hide();
        myMacContents.find('.site-my-mac__contents-signed_in').show();
      } else {
        myMacContents.find('.site-my-mac__contents-signin').show();
        myMacContents.find('.site-my-mac__contents-signed_in').hide();
      }
    },

    isLoyaltyMember: function () {
      var is_loyalty_member = site.userInfoCookie.getValue('is_loyalty_member');

      return is_loyalty_member == '1';
    },

    isRewardsEligible: function () {
      var is_rewards_eligible = site.userInfoCookie.getValue('is_rewards_eligible');

      return is_rewards_eligible == '1';
    },

    getLoyaltyLevel: function () {
      var loyalty_level = site.userInfoCookie.getValue('loyalty_level');

      return loyalty_level;
    },

    getLoyaltyPoints: function () {
      var loyalty_points = site.userInfoCookie.getValue('current_available');

      return loyalty_points;
    },

    getTierFooterSelector: function (loyaltyLevel) {
      return '.site-my-mac__contents-footer__loyalty-tier' + loyaltyLevel;
    },

    attachCsrfToken: function (form, csrftoken) {
      if (form.find('[name="_TOKEN"]').length !== 0) {
        form.find('[name="_TOKEN"]').val(csrftoken);
      } else {
        $('<input>').attr({
          type: 'hidden',
          name: '_TOKEN'
        }).val(csrftoken).appendTo(form);
      }
    },

    bindIamSignin: function () {
      // Show overlay on click.
      var self = this;
      var $thisFormatter = $(this);
      var disableGnavOverlay = Drupal.settings.disable_gnav_signin_overlay ? Drupal.settings.disable_gnav_signin_overlay : false;
      var returnUrl = window.location.pathname;
      var signinUrl = '/account/signin.tmpl';

      siteMyMacV1.formatter.on('click', '.js-my_mac_link', function () {
        if (disableGnavOverlay && self.get('signed_in') !== '1') {
          (returnUrl === '/' || returnUrl === signinUrl) ? window.location = signinUrl : window.location = signinUrl + '?return_url=' + returnUrl;

          return false;
        }
        if (self.get('is_mobile').toString() === '0') {
          if (!$thisFormatter.hasClass('active')) {
            $(window).trigger('window::show_login_overlay');
            $(this).children('.js-my_mac_link').attr('aria-expanded', 'true');
            site.restrict_navigation($('.js-my-mac-overlay'));
          } else {
            $thisFormatter.removeClass('active');
            $thisFormatter.trigger('clickoutside');
          }

          return false;
        }
      });
      siteMyMacV1.formatter.on('mouseleave', function () {
        $(this).children('.js-my_mac_link').attr('aria-expanded', 'false');
        $('input:focus', $thisFormatter).blur();
      });
    },

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var formatter = self.get('formatter');
      var $forgotPassword = $('.js-forgot-password', context);
      var $signinEmailAddress = $('.js-return-user-email-gnav', context);
      var $errorListNode = $('.js-signin-errors', context);

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $signinEmailAddress,
          errorListNode: $errorListNode,
          forgotPasswordLink: $forgotPassword
        });
      }

      formatter.each(function () {
        var thisFormatter = $(this);
        var hasIAM = Drupal.settings && Drupal.settings.iam_signin;

        if (hasIAM) {
          generic.jsonrpc.fetch({
            method: 'vulcan.getConfig',
            params: [{ section: 'iam' }],
            async: false,
            onBoth: function (jsonRpcResponse) {
              var iamData = jsonRpcResponse.getValue();

              if (iamData && iamData.enabled && iamData.redirect_url) {
                thisFormatter.find('.js-my_mac_link').once().on('click', function (e) {
                  e.preventDefault();
                  if (window.location.href.indexOf(iamData.redirect_url) === -1) {
                    window.location = iamData.redirect_url;
                  } else {
                    self.bindIamSignin();
                  }
                });
              } else {
                self.bindIamSignin();
              }
            }
          });
        } else {
          self.bindIamSignin();
        }
        // shipping info overlay
        thisFormatter.find('.overlay-toggle').once().click(function () {
          var content = thisFormatter.find('.site-my-mac__overlay-content');

          if (content.length > 0) {
            generic.overlay.launch({
              content: content.clone(true),
              includeBackground: true,
              includeCloseLink: true,
              initialHeight: 0,
              height: 0,
              width: '50%',
              onComplete: function () {
                $(this).colorbox.resize();
              }
            });
          }

          return false;
        });

        // Trigger for sign-in overlay.
        $(window).on('window::show_login_overlay', function () {
          if (!thisFormatter.hasClass('active')) {
            // Timeout ensures click events complete.
            setTimeout(function () {
              self.showMyMacContents(thisFormatter);
            }, 100);
          }
        });

        // swap content if signed in
        if (self.get('signed_in') == '1') {
          self.setContentsTitle(thisFormatter, true);
          self.setMyMacContents(thisFormatter, true);

          var $macSelectPoints = $('.js-mac-select-points');

          if ($macSelectPoints) {
            var points = site.userInfoCookie.getValue('current_available');

            $macSelectPoints.html(points);
          }

          var loyaltyLink = '/account/loyalty/index.tmpl';
          var nonloyaltyLink = '/account/mac-lover.tmpl#offers';
          var redirectLink = parseInt(site.userInfoCookie.getValue('is_loyalty_member')) ? loyaltyLink : nonloyaltyLink;

          $('.js-mac-lover-redirect-link').attr('href', redirectLink);

          if (!!self.isLoyaltyMember() && !!self.isRewardsEligible()) {
            var $footers = thisFormatter.find('.site-my-mac__contents-footer');
            var loyaltyLevel = Number(self.getLoyaltyLevel());
            var loyaltyPoints = Math.round(Number(self.getLoyaltyPoints()));

            if (loyaltyLevel >= 0 && loyaltyLevel <= 3) {
              // show personalized messaging about their specific benefits
              var tierFooter = self.getTierFooterSelector(loyaltyLevel);
              var $tierFooter = $(tierFooter);

              $footers.addClass('hidden');
              $tierFooter.removeClass('hidden');
              $tierFooter.find('.js-total-loyalty-points').html(loyaltyPoints);
            }
          } else {
            $('.js-my-mac-lover').addClass('hidden');
          }
        } else {
          if (sessionStorage.show_my_mac) {
            sessionStorage.removeItem('show_my_mac');
          }

          var facebookSignInForm = thisFormatter.find('.js-facebook_signin');
          var $signInForm = thisFormatter.find('form');
          var formAction = $signInForm.attr('action');

          if (formAction.match(/^http[s]?:/)) {
            // form may already have absolute url (with locale prefixes if this is a hub site), just make sure it's https
            $signInForm.attr('action', formAction.replace('http:', 'https:'));
          } else {
            // default behaviour: assume form POSTs to /account/signin.tmpl and prepend https:// and hostname
            $signInForm.attr('action', 'https://' + window.location.hostname + formAction);
          }

          if (sessionStorage.getItem('loyalty_redirect')) {
            thisFormatter.find('[name="RETURN_URL"]').val(sessionStorage.getItem('loyalty_redirect'));
          } else {
            if (window.location.pathname.search('password_request') > 0 || window.location.pathname.search('confirm') > 0 || window.location.pathname.search('signin') > 0) {
              thisFormatter.find('[name="RETURN_URL"]').val('/account/index.tmpl');
            } else if (location.pathname.match('/checkout/checkout.tmpl')) {
              facebookSignInForm.attr('action', '/checkout/checkout.tmpl');
              facebookSignInForm.find('[name="RETURN_URL"]').val('/checkout/checkout.tmpl');
            } else {
              thisFormatter.find('[name="RETURN_URL"]').val(window.location.pathname + window.location.search + window.location.hash);
            }
          }

          if (settings.enable_csrftoken) {
            $signInForm.one('submit', function () {
              // Include CSRF token.
              if ($.cookie('csrftoken')) {
                self.attachCsrfToken($signInForm, $.cookie('csrftoken'));

                return true;
              } else {
                $('body').addClass('ajax-wait');
                $.when(Drupal.behaviors.csfrRpc.attach()).done(
                  function () {
                    $(document).on('csrf.success', function () {
                      $('body').removeClass('ajax-wait');
                      self.attachCsrfToken($signInForm, $.cookie('csrftoken'));
                      $signInForm.find('input[type="submit"]').trigger('click');

                      return true;
                    });
                  }
                ).fail(function () {
                  $('body').removeClass('ajax-wait');
                });
              }

              return false;
            });
          }
        }

        // set initial overlay offset
        self.setContentsOffset(thisFormatter);

        // hide overlay on resize, adjust overlay offset
        $(window).resize(_.debounce(function () {
          self.setContentsOffset(thisFormatter);
          if (thisFormatter.hasClass('active')) {
            thisFormatter.trigger('clickoutside');
          }
        }, 50));

        $(window).on('gnavdata_success', function (e, results) {
          self.setMyMac(self, thisFormatter);
          // $(window).load(function() {
          //  _.defer(function() {
          //    setTimeout(function() {
          //      if (!sessionStorage.show_my_mac && self.get('signed_in') == "1") {
          //        sessionStorage.show_my_mac = "true";
          //        self.showMyMacContents(thisFormatter);
          //      }
          //    }, 1000);
          //  });
          // });
        });
      });
    },

    attached: false
  };

  $('.favorites-none__sign').click(function (e) {
    /* On PC pops open the overlay, but on mobile needs to redirect, so has the URL in the link  */
    if (Drupal.behaviors.siteMyMacV1.get('is_mobile') == '0') {
      e.preventDefault();
      $(window).trigger('window::show_login_overlay');
    }

    return true;
  });
})(jQuery, Drupal, window.generic || {}, window.site || {});
